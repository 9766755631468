import { Component, Input, OnInit } from '@angular/core';
import { Fondo } from '../../interfaces';

@Component({
  selector: 'app-fondo',
  templateUrl: './fondo.component.html',
  styleUrls: ['./fondo.component.scss']
})
export class FondoComponent implements OnInit {

  @Input() fondo: Fondo;

  constructor() { }

  ngOnInit(): void {
  }

}
