<h2>
    {{ fondo.fondoNombre }}
</h2>
<div class="table-responsive">
    <table class="table text-nowrap">
        <thead>
            <tr>
                <th scope="col" class="name" style="width: 45%">Nombre</th>
                <th scope="col" class="representation" style="width: 30%">¿Qué representa?</th>
                <th scope="col" class="currency" style="width: 20%">Moneda</th>
                <th scope="col" class="percent" style="width: 5%; text-align: right;">% Fondo</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let detalle of fondo.fondoDetalle">
                <td>{{ detalle.nombre }}</td>
                <td>{{ detalle.representacion }}</td>
                <td>{{ detalle.moneda }}</td>
                <td style="text-align: right;">{{ detalle.porcentaje | number:'1.2-2'}}%</td>
            </tr>
        </tbody>
    </table>
</div>