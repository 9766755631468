import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Fondo } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class FondosService {

  constructor(
    private http: HttpClient
  ) { }

  getFondos(): Observable<Fondo[]> {
    const headers = new HttpHeaders().set('Ocp-Apim-Subscription-Key', environment.apiKey);
    const url = `${environment.apiUrl}/detallefondos`;
    return this.http.get<Fondo[]>(url, { headers });
  }

}
