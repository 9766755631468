import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Fondo } from 'src/app/interfaces';
import { FondosService } from 'src/app/services/fondos.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  loading = true;
  fondos: Fondo[] = [];
  availablesFondos = [
    {
      name: 'conservador',
      position: 0
    },
    {
      name: 'moderado',
      position: 1,
    },
    {
      name: 'arriesgado',
      position: 2
    }
  ];

  constructor(
    private fondosService: FondosService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(({ fondoName }) => {
      this.fondosService.getFondos().subscribe((fondos: any) => {

        if (fondoName && this.fondoNameIsValid(fondoName)) {
          const { position } = this.getFondoByName(fondoName);
          fondos = [fondos[position]];
        }

        this.fondos = fondos;
        this.loading = false;
      });
    });

  }

  private fondoNameIsValid(fondoName: string): boolean {
    return this.availablesFondos.map(fondo => fondo.name).includes(fondoName.trim().toLowerCase());
  }

  private getFondoByName(fondoName: string) {
    return this.availablesFondos.find(fondo => fondo.name === fondoName);
  }

}
