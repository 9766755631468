<ng-container *ngIf="!loading; else loadingTemplate">
    <app-fondo [fondo]="fondo" *ngFor="let fondo of fondos"></app-fondo>
</ng-container>
<ng-template #loadingTemplate>
    <section class="loading-content">
        <img
            src="https://cdn.soyfocus.com/static/V2/common/focus_spinner.gif"
            alt="spinner"
            width="65"
        />
    </section>
</ng-template>